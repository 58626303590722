import clsx from 'clsx';
import PropTypes from 'prop-types';
import MUISelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export default function Select({
  name = '',
  value = '',
  options = [],
  focused = false,
  placeholder = '',
  onBlur = () => { },
  onFocus = () => { },
  onChange = () => { },
  onClickSelectOption = () => { },
  size = 'md',
  className,
  disabled = false,
  error,
}) {
  return (
    <div className={`${className} form-input select-input flex-row`}>
      <MUISelect
        id={name}
        value={value}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
        className={clsx(' select', focused && 'focused', size, error ? 'error' : 'clean')}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        {placeholder && (
          <MenuItem value="default" style={{ opacity: 0.38, backgroundColor: '#d8a87829' }}>
            <em>{placeholder}</em>
          </MenuItem>
        )}

        {options.map((o) => (
          <MenuItem
            value={o.value}
            onClick={onClickSelectOption}
            key={o.value}
            disabled={o?.disabled || false}
          >
            {o.label}
          </MenuItem>
        ))}
      </MUISelect>
      {error && <div className="form-input-error">{error}</div>}
    </div>
  );
}

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.array,
  focused: PropTypes.bool,
  placeholder: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onChange: PropTypes.func,
};
