import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';

export default function ScaleYourBusiness({ onSignup }) {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <div className="scale">

      <div className="info">
        <h2>
          {t('Scale Your Business')}
        </h2>
        <p>
          {t("By applying our tactics to grow and preserve wealth, and combining them with specific innovations, we've navigated past traditional constraints. Our goal is to bring to our community insights and experiences from industry gurus, highlighting the effective use of motivation, strategy, and knowledge of key sectors in elevating net worth.")}
        </p>
        <Button onClick={() => { onSignup(); }} style={{ width: '150px', height: 'fit-content' }}> 
          {' '}
          {t('Join Now')}
        </Button>

      </div>
      <div className="background">
        <img src="assets/arrow.webp" alt="" />
      </div>

    </div>
  );
}
