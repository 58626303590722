import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';

export default function Team({ onSignup }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="team">

      <div className="background">

        <img src="assets/team.webp" alt="team" />

      </div>

      <div className="info">

        <h3>
          {t(' A unique team of Sector Experts')}
        </h3>

        <p>
          {t("Each team member brings a wealth of experience and insight from various industries, contributing to a rich tapestry of learning and development opportunities for our members. We're dedicated to pushing the boundaries of what an online mastermind can achieve, facilitating growth, innovation, and collaboration on a scale like no other group online.")}
        </p>

        <Button onClick={() => { onSignup(); }} style={{ width: '150px', height: 'fit-content' }}> 
          {' '}
          {t('Join Now')}
        </Button>

      </div>

    </div>
  );
}
