/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
import { Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import VideoModal from './video-modal';

export default function Header({ onSignup }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };
  return (
    <div className="header">
      <div className="video-background">

        <div
          data-poster-url="https://uploads-ssl.webflow.com/65a5c74d475be4e728d1e70d/65fc8e84a4f396599f5cd9ba_pexels-taryn-elliott-5309354 (720p)-poster-00001.jpg"
          data-video-urls="https://uploads-ssl.webflow.com/65a5c74d475be4e728d1e70d/65fc8e84a4f396599f5cd9ba_pexels-taryn-elliott-5309354 (720p)-transcode.mp4,https://uploads-ssl.webflow.com/65a5c74d475be4e728d1e70d/65fc8e84a4f396599f5cd9ba_pexels-taryn-elliott-5309354 (720p)-transcode.webm"
          data-autoplay="true"
          data-loop="true"
          data-wf-ignore="true"
          className="video-container"
        >
          <video
            id="eb6452c2-d7ac-572d-6d5e-dda5041023ed-video"
            autoPlay
            loop
            style={{
              backgroundImage: "url('https://uploads-ssl.webflow.com/65a5c74d475be4e728d1e70d/65fc8e84a4f396599f5cd9ba_pexels-taryn-elliott-5309354 (720p)-poster-00001.jpg')",
            }}
            muted
            playsInline
            data-wf-ignore="true"
            data-object-fit="cover"
          
          >
            <source
              src="https://uploads-ssl.webflow.com/65a5c74d475be4e728d1e70d/65fc8e84a4f396599f5cd9ba_pexels-taryn-elliott-5309354 (720p)-transcode.mp4"
              data-wf-ignore="true"
            />
            <source
              src="https://uploads-ssl.webflow.com/65a5c74d475be4e728d1e70d/65fc8e84a4f396599f5cd9ba_pexels-taryn-elliott-5309354 (720p)-transcode.webm"
              data-wf-ignore="true"
            />
          </video>
        
        </div>
      </div>

      <VideoModal modalOpen={modalOpen} closeModal={closeModal} />
      <div className="video-top">
        <Grid container className="content">
 
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="middle">
            <img alt="logo" src="assets/trec-global.svg" />
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="middle">
            <Typography className="subtitle-1">
              {t(' Building the world’s largest global mastermind community')}
            </Typography>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="middle">
            <div onClick={openModal} className="play-button">
              <img src="assets/play.svg" loading="lazy" alt="" className="svg" />
            </div>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="middle">
            <Typography className="subtitle-2">

              {t('A collective network, product suite, and community focused on success')}            
            </Typography>
          </Grid>

          <Grid item xl={12} lg={12} md={12} sm={12} xs={12} className="middle">
            <Button onClick={() => { onSignup(); }} style={{ width: '150px' }}> 
              {' '}
              {t('Join Now')}
            </Button>
          </Grid>

        </Grid>
      </div>

    </div>
 
  );
}
