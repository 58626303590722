import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import Button from '../../../components/Button';
import { getFromSession } from '../../../utils/session';

export default function WhatIs({ onSignup }) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="what-is">

      <div className="what__info-row">

        <div className="cont">

          <div className="what__lo">

            <Typography className="title">
              {t('What is TREC Global?')}
            </Typography>

            <p className="description">
              {t(` TREC Global is a collective network, digital product suite, 
              and community focused on success. As a nine-figure wealth generator,
              there is significant demand for my expertise and for access to my network 
              of accomplished professionals and thought leaders in the cryptocurrency, 
              real estate, and technology sectors. TREC Global is the answer to that demand.`)}
            </p>

          </div>

        </div>

      </div>

      <div className="what__img-row">
        <img src="assets/darka.webp" alt="darka" />
        <div className="what__btn-w">
          <Button onClick={() => { onSignup(); }} style={{ width: '150px' }}> 
            {' '}
            {t('Join Now')}
          </Button>
        </div>
      </div>
    </div>
  );
}
