/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';

export default function OurPhilosophy({ onSignup }) {
  const navigate = useNavigate();

  const { t } = useTranslation();
  return (
    <div className="philosophy">

      <div className="content">
        <div className="title">
          {t(' Our Philosophy')}
        </div>

        <div className="list">

          <div className="item">
            <img src="assets/shake.svg" alt="shaking hands" />

            <p>
              {' '}
              {t('Learn around like-minded people, and from like-minded mentors.')}
            </p>
          </div>

          <div className="item">
            <img src="assets/suitcase.svg" alt="shaking hands" />

            <p>
              {' '}
              {t('Teaching how to make money through action, not a textbook.')}
            </p>
          </div>

          <div className="item">
            <img src="assets/group-up.svg" alt="shaking hands" />

            <p>
              {' '}
              {t('Quickly position our members with the opportunity to make money')}
            </p>
          </div>
        </div>
        <div className="img">
          <img src="assets/sunset.webp" alt="sunset" />
        </div>
        <div className="info">

          <h3>
            {t('Unlock your full potential')}
          </h3>
          <Button onClick={() => { onSignup(); }} style={{ width: '150px', height: 'fit-content' }}>
            {t('Join Now')}
            {' '}
          </Button>
          <p>
            {t("TREC Gloal brigs together new investors in the technology, real estate and crypto sectors with experienced entrepreneurs who have generated seven and eight figure fortunes. We've developed thepremier online solution designed to deliver valueto both experienced and new investors. Tap into affiliate opportunities to earn your initial commissions and access wisdom from industry titans aimed at elevating you to unprecedented success levels. At TREC Global, our mission is to support your achievement.")}
          </p>

        </div>

      </div>

    </div>
  );
}
