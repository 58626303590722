// Backend URL
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Google Web Client ID
export const GOOGLE_CLOUD_ID = process.env.REACT_APP_GOOGLE_CLOUD_ID;

// Sentry DNS
export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;

// Default Per Page
export const PER_PAGE = 20;

// LAYOUT
export const DRAWER_WIDTH = 302;
export const APP_BAR_HEIGHT = 80;

// USER PACKAGES
export const USER_PACKAGES = {
  SILVER: 'Silver',
  PLATINUM: 'Platinum',
  GOLD: 'Gold',
};
