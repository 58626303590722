/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
export default function VideoModal({ modalOpen, closeModal }) {
  return (

    <div>
     
      {modalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content">
            <iframe
              title="YouTube video"
              className="embedly-embed"
              src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEx2iAyaEElQ%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEx2iAyaEElQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEx2iAyaEElQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube"
              width="940"
              height="528"
              scrolling="no"
              frameBorder="0"
              allow="autoplay; fullscreen; encrypted-media; picture-in-picture;"
              allowFullScreen
            />
          </div>
        </div>
      )}
    </div>
  );
}
