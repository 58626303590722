import axios from 'axios';
import i18next from 'i18next';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../constants';
import { useAuthentication } from '../store';
/**
 * Function used to handle error responses
 * @param   {any} err  Error value
 * @returns {any}      Handled error response
 */
const apiErrorHandler = (err) => {
  if (axios.isAxiosError(err)) {
    if (err.code === 'ERR_NETWORK') {
      toast.error('Something went wrong. Please try again later!');
      return { statusCode: 500, message: 'Something went wrong. Please try again later!' };
    }
    const errorStatusCode = err?.response?.status;
    switch (errorStatusCode) {
      // Conflict
      case 409: {
        const error = err;
        if (error.response?.data) {
          return {
            [error.response.data.field]: [error.response.data.message],
          };
        }
        return error.response?.data.message;
      }
      // Unprocessable Content
      case 422: {
        const error = err;
        if (error.response?.data.errors) {
          return error.response.data.errors.reduce((errors, fieldError) => {
            if (errors[fieldError.field]) {
              errors[fieldError.field].push(...Object.values(fieldError.constraints));
            } else {
              errors[fieldError.field] = Object.values(fieldError.constraints);
            }
            return errors;
          }, {});
        }
        return error.response?.data.message;
      }

      // Server errors
      case 500: 
      case 502: 
      case 504: {
        console.log(err);
        toast.error('Something went wrong. Please try again later!');
        return 'Something went wrong. Please try again later!';
      }
      default: {
        const error = err;
        return error.response?.data.message;
      }
    }
  } else {
    return err?.message;
  }
};

// Create axios instance
export const api = axios.create({ baseURL: BASE_URL });

/**
 * Function used to catch response errors and merge configurations
 * @param   {Object}  config               Call config like url, headers
 * @param   {Boolean} returnFullResponse  Return all response or only the data
 * @returns {Object}                      Response of the call
 */
async function call(config, returnFullResponse) {
  try {
    const res = await api.request({
      ...(config || {}),
      headers: config.noNgrok
        ? config?.headers || {}
        : {
          'ngrok-skip-browser-warning': true,
          ...(config?.headers || {}),
        },
    });
    return returnFullResponse ? res : res.data;
  } catch (e) {
    console.log(e);
    throw apiErrorHandler(e);
  }
}

/**
 * Hook used for non authorized calls
 */
const useNonAuthApi = () => {
  const nonAuthGet = useCallback(
    async (url, config) => call({ method: 'GET', url, ...config }),
    [],
  );

  const nonAuthPost = useCallback(
    async (url, config) => call({ method: 'POST', url, ...config }),
    [],
  );

  const nonAuthPut = useCallback(
    async (url, config) => call({ method: 'PUT', url, ...config }),
    [],
  );

  const nonAuthDelete = useCallback(
    async (url, config) => call({ method: 'DELETE', url, ...config }),
    [],
  );

  return {
    call,
    nonAuthGet,
    nonAuthPost,
    nonAuthPut,
    nonAuthDelete,
  };
};

/**
 * Hook used for authorized calls
 */
const useApi = () => {
  const { token } = useAuthentication();

  const authCall = useCallback(
    async (config) => {
      const authConfig = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
          ...config.headers,
        },
      };
      return call(authConfig);
    },
    [token],
  );

  const authGet = useCallback(
    async (url, config) => authCall({
      method: 'GET',
      url,
      ...config,
    }),
    [authCall],
  );

  const authPost = useCallback(
    async (url, config) => authCall({
      method: 'POST',
      url,
      ...config,
    }),
    [authCall],
  );

  const authPut = useCallback(
    async (url, config) => authCall({
      method: 'PUT',
      url,
      ...config,
    }),
    [authCall],
  );

  const authPatch = useCallback(
    async (url, config) => authCall({
      method: 'PATCH',
      url,
      ...config,
    }),
    [authCall],
  );

  const authDelete = useCallback(
    async (url, config) => authCall({
      method: 'DELETE',
      url,
      ...config,
    }),
    [authCall],
  );

  return {
    call: authCall,
    authGet,
    authPost,
    authPut,
    authPatch,
    authDelete,
  };
};

/**
 * Hook used for authorized calls with language param
 */
const useApiWithLanguage = () => {
  const { token } = useAuthentication();

  const authCall = useCallback(
    async (config) => {
      const authConfig = {
        ...config,
        headers: {
          Authorization: `Bearer ${token}`,
          ...config.headers,
        },
      };
      return call(authConfig);
    },
    [token],
  );

  const authGet = useCallback(
    async (url, config) => authCall({
      method: 'GET',
      url: `/${i18next.language}${url}`,
      ...config,
    }),
    [authCall, i18next.language],
  );

  const authPost = useCallback(
    async (url, config) => authCall({
      method: 'POST',
      url: `/${i18next.language}${url}`,
      ...config,
    }),
    [authCall, i18next.language],
  );

  const authPut = useCallback(
    async (url, config) => authCall({
      method: 'PUT',
      url: `/${i18next.language}${url}`,
      ...config,
    }),
    [authCall, i18next.language],
  );

  const authPatch = useCallback(
    async (url, config) => authCall({
      method: 'PATCH',
      url: `/${i18next.language}${url}`,
      ...config,
    }),
    [authCall, i18next.language],
  );

  const authDelete = useCallback(
    async (url, config) => authCall({
      method: 'DELETE',
      url: `/${i18next.language}${url}`,
      ...config,
    }),
    [authCall, i18next.language],
  );

  return {
    call: authCall,
    authGet,
    authPost,
    authPut,
    authPatch,
    authDelete,
  };
};

export { useNonAuthApi, useApiWithLanguage };
export default useApi;
