import { Container } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function AuthLayout({ children, style }) {
  const { t } = useTranslation();

  return (
    <div className="layout-container">
      <div className="flex-1 flex-column pt-15 pb-15">
        <div className="layout-header flex-row justify-center">
          <img
            style={{ width: '240px' }}
            alt="logo-white"
            src="/assets/layout/tft_logo_white.svg"
          />
        </div>

        <Container className="flex-1 mt-12 mb-12 layout-card" style={style} maxWidth="sm">
          {children}
        </Container>

        <div className="mt-0 layout-footer">
          <p>{`© ${new Date().getFullYear()} The Founders Team.`}</p>
          <p>{t('All rights reserved.')}</p>
        </div>
      </div>
    </div>
  );
}
