/* eslint-disable import/no-named-as-default */
import {
  Routes, Route, BrowserRouter, Navigate,
} from 'react-router-dom';
import Payments from '../features/Payments';
import PublicRoute from './publicRoute';
import ProtectedRoute from './protectedRoute';
import Login from '../features/Authentication/Login';
import SignUp from '../features/Authentication/SignUp';
import PayNow from '../features/Authentication/PayNow';
import GetParent from '../features/Authentication/GetParent';
import LoginAdmin from '../features/Authentication/LoginAdmin';
import SignupSuccess from '../features/Authentication/SignupSucces';
import ThankYouPage from '../features/Authentication/ThankYou';
import UserExists from '../features/Authentication/UserExists';
import SignupForm from '../features/Authentication/SignupForm';
import Lander from '../features/Lander';
import VerifyEmailConfirm from '../features/Authentication/VerifyEmail';
import TokenExpired from '../features/Authentication/TokenExpired';
import PaymentStatus from '../features/Authentication/PaymentStatus';

export default function Navigation() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_URL_BASE_NAME || ''}>
      <Routes>
        <Route path="/*" element={<Navigate to="/" />} />

        <Route
          path="/"
          element={(
            <PublicRoute>
              <Lander />
            </PublicRoute>
          )}
        />
        <Route
          path="/dashboard/signin"
          element={(
            <PublicRoute>
              <Login />
            </PublicRoute>
          )}
        />

        <Route
          path="/payment-status"
          element={(
            <PublicRoute>
              <PaymentStatus />
            </PublicRoute>
          )}
        />
        <Route
          path="/verify-email"
          element={(
            <PublicRoute>
              <VerifyEmailConfirm />
            </PublicRoute>
          )}
        />

        <Route
          path="/token-expired"
          element={(
            <PublicRoute>
              <TokenExpired />
            </PublicRoute>
          )}
        />

        <Route
          path="/dashboard/signin/form"
          element={(
            <PublicRoute>
              <SignupForm />
            </PublicRoute>
          )}
        />

        <Route
          path="/thank-you"
          element={(
            <PublicRoute>
              <ThankYouPage />
            </PublicRoute>
          )}
        />

        <Route
          path="/user-exists"
          element={(
            <PublicRoute>
              <UserExists />
            </PublicRoute>
          )}
        />

        <Route
          path="/dashboard/registration/:step"
          element={(
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          )}
        />

        <Route
          path="/dashboard/enroll-now/pay"
          element={(
            <PublicRoute>
              <PayNow />
            </PublicRoute>
          )}
        />

        <Route
          path="/renew-account"
          element={(
            <ProtectedRoute>
              <PayNow />
            </ProtectedRoute>
          )}
        />

        <Route
          path="/dashboard/enroll-now/success"
          element={(
            <PublicRoute>
              <SignupSuccess />
            </PublicRoute>
          )}
        />

        <Route
          path="/e/:username"
          element={(
            <PublicRoute>
              <GetParent />
            </PublicRoute>
          )}
        />

        <Route
          path="/admin/signin"
          element={(
            <PublicRoute>
              <LoginAdmin />
            </PublicRoute>
          )}
        />

        <Route path="/payments/final" element={<Payments />} />
      </Routes>
    </BrowserRouter>
  );
}
