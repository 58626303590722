/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import VideoModal from './video-modal';

export default function Results() {
  const { t } = useTranslation();

  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <div className="results">
      <VideoModal modalOpen={modalOpen} closeModal={closeModal} />

      <div className="container">

        <div className="content">

          <h2>{t('Get Results Fast')}</h2>

          <div className="list">

            <p>
              {t('Other programs from industry leading experts can cost up to $30,000 for a fraction of the access provided by TREC Global, for access to only ONE expert! We provide access to new prominent masterminds every month, for a fraction of the cost.')}
            </p>

            <p>
              {t('Gain access to opportunities to make money as you learn. We aim to regularly connect our members with exposure to attractive deals and investment opportunities suitable for both beginning and experienced investors.')}
            </p>

            <p>
              {t('Learn the secrets of financial independence. If you already knew them… you wouldn’t be here!')}
            </p>

          </div>

          <div className="a" aria-label="open lightbox" aria-haspopup="dialog">
            <img
              src="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e6349b26f95a993a0bf0_Frame%2096805_q85.webp"
              loading="lazy"
              sizes="(max-width: 767px) 90vw, (max-width: 991px) 40vw, 37vw"
              srcSet="
          https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e6349b26f95a993a0bf0_Frame%2096805_q85-p-500.webp 500w,
          https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e6349b26f95a993a0bf0_Frame%2096805_q85-p-800.webp 800w,
          https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e6349b26f95a993a0bf0_Frame%2096805_q85-p-1080.webp 1080w,
          https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e6349b26f95a993a0bf0_Frame%2096805_q85-p-1600.webp 1600w,
          https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e6349b26f95a993a0bf0_Frame%2096805_q85-p-2000.webp 2000w,
          https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e6349b26f95a993a0bf0_Frame%2096805_q85.webp 2015w"
              alt=""
              className="img-a"
            />
            <div onClick={openModal} className="lb__play-btn">
              <img
                src="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e8e63f18db791e4d891d15_Rectangle%201107.svg"
                loading="lazy"
                alt=""
                className="svg"
              />
            </div>
            <script type="application/json" className="w-json">
              {`
          {
            "items": [
              {
                "url": "https://www.youtube.com/watch?v=Ex2iAyaEElQ",
                "originalUrl": "https://www.youtube.com/watch?v=Ex2iAyaEElQ",
                "width": 940,
                "height": 528,
                "thumbnailUrl": "https://i.ytimg.com/vi/Ex2iAyaEElQ/hqdefault.jpg",
                "html": "<iframe class=\\"embedly-embed\\" src=\\"//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FEx2iAyaEElQ%3Ffeature%3Doembed&display_name=YouTube&url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DEx2iAyaEElQ&image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FEx2iAyaEElQ%2Fhqdefault.jpg&key=96f1f04c5f4143bcb0f2e68c87d65feb&type=text%2Fhtml&schema=youtube\\" width=\\"940\\" height=\\"528\\" scrolling=\\"no\\" title=\\"YouTube embed\\" frameborder=\\"0\\" allow=\\"autoplay; fullscreen; encrypted-media; picture-in-picture;\\" allowfullscreen=\\"true\\"></iframe>",
                "type": "video"
              }
            ],
            "group": ""
          }
        `}
            </script>
          </div>

        </div>

      </div>

    </div>
  );
}
