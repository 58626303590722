import { useCallback, useContext } from 'react';
import { useNonAuthApi } from '../../../utils/api';
import { AuthenticationContext } from '../../../utils/store/authentication';

export default function useAuthenticationApi() {
  const { nonAuthPost, nonAuthGet } = useNonAuthApi();
  const { authenticateUser } = useContext(AuthenticationContext);

  const signUp = useCallback((data = {}) => nonAuthPost('/signup', { data }), [nonAuthPost]);

  const logIn = useCallback((data = {}) => nonAuthPost('/auth/login', { data }), [nonAuthPost]);

  const logInGoogle = useCallback(
    async (data = {}) => {
      try {
        const res = await nonAuthPost('/auth/google/login', { 
          data: {
            ...data,
            source: 'google',
          },
        });

        return res;
      } catch (err) {
        console.log('-logInGoogle-', err);
        throw err;
      }
    },
    [nonAuthPost],
  );

  const signUpEmail = useCallback(
    async (data = {}) => {
      try {
        const res = await nonAuthPost('/auth/signup', { 
          data: {
            ...data,
            source: 'email',
          },
        });

        return res;
      } catch (err) {
        console.log('-logInEmail-', err);
        throw err;
      }
    },
    [nonAuthPost],
  );

  const logInApple = useCallback(
    async (data = {}) => {
      try {
        const res = await nonAuthPost('/auth/apple/login', {
          data: {
            ...data,
            source: 'apple',
          },
        });
        return res;
      } catch (err) {
        console.log('-logInApple-', err); 
        throw err;
      }
    },
    [nonAuthPost],
  );

  const logInAdmin = useCallback(
    async (data = {}) => {
      try {
        const res = await nonAuthPost('/auth/login', { data });
        authenticateUser(res.user, res.token);
        return res;
      } catch (err) {
        console.log(err);
        throw err;
      }
    },
    [nonAuthPost, authenticateUser],
  );

  const getGoogleUser = useCallback(
    (accessToken = '') => nonAuthGet('/oauth2/v3/userinfo', {
      baseURL: 'https://www.googleapis.com',
      headers: { Authorization: `Bearer ${accessToken}` },
      noNgrok: true,
    }),
    [nonAuthGet],
  );

  const getAffiliate = useCallback(
    (data = {}) => nonAuthPost('/auth/collect', { data }),
    [nonAuthPost],
  );
  const getParent = useCallback(
    (username) => nonAuthGet(`/dashboard/enroll/${username}`),
    [nonAuthGet],
  );
  const getPackages = useCallback(() => nonAuthGet('/pass-types'), [nonAuthGet]);

  const enrollNow = useCallback(
    async (data = {}) => nonAuthPost('/dashboard/enroll', { data }),
    [nonAuthPost],
  );

  const checkToken = useCallback(
    // eslint-disable-next-line no-shadow
    (token) => nonAuthGet('/auth/check-token', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
    [nonAuthGet],
  );

  const payWithCode = useCallback(
    // eslint-disable-next-line no-shadow
    (user, key) => nonAuthPost('/dashboard/enroll/pay/key', { data: { user, key } }),
    [nonAuthPost],
  );

  return {
    logIn,
    signUp,
    enrollNow,
    logInAdmin,
    payWithCode,
    logInGoogle,
    checkToken,
    getPackages,
    getParent,
    getAffiliate,
    logInApple,
    getGoogleUser,
    signUpEmail,
  };
}
