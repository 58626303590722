/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { useMemo } from 'react';
import { Container } from '@mui/material';

export default function Layout({
  children,
  maxWidth = 'sm',
  size = 'md',
  className: cN,
  onClick = () => {},
  maxHeight = '100%',
}) {
  const className = useMemo(() => {
    switch (size) {
      case 'sm':
        return 'pt-5 pb-5';
      default:
        return 'pt-10 pb-10 pl-5 pr-5';
    }
  }, [size]);

  return (
    <div onClick={onClick} className={clsx('card-container', cN)}>
      <Container sx={{ mt: '35px', maxHeight }} className="flex-1 layout-card" maxWidth={maxWidth}>
        <div className={clsx(className, 'card-main')}>{children}</div>
      </Container>
    </div>
  );
}
