/* eslint-disable max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  CircularProgress, Typography, Container, Divider,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import useAuthenticationApi from '../api';
import Button from '../../../components/Button';
import Layout from '../../../components/AuthLayout';
import { getFromSession } from '../../../utils/session';
import renderModals from '../../Admin/Members/components/View/components/modals';
import useApi, { useNonAuthApi } from '../../../utils/api';
import PurchasePassModal from '../../Memberships/components/PurchasePasses/components/modal';
import usePurchasePass from '../../Memberships/components/PurchasePasses/hook';
import TextInput from '../../../components/Inputs/TextInput';
import EnterKey from './components/EnterKey';
import AffiliateLink from './components/AffiliateLink';
import StripePay from './components/StripePay';
import RadioGroup from '../../../components/Inputs/RadioGroup';

export default function PayNow() {
  const navigate = useNavigate();
  const { nonAuthPost, nonAuthGet } = useNonAuthApi();
  const { handleModalClose, setPurchaseData, purchaseData } = usePurchasePass();
  const { logInGoogle, logInApple } = useAuthenticationApi();

  const { t } = useTranslation();
  const [key, setKey] = useState('');
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalData, setModalData] = useState(null);
  const { getPackages, payWithCode } = useAuthenticationApi();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [userDataTrigger, setUserDataTrigger] = useState();
  const signupUser = useMemo(() => getFromSession('signupUser'), [userDataTrigger]);
  // const signupParent = useMemo(() => getFromSession('signupParent'), [userDataTrigger]);
  // const SignupSID = useMemo(() => sessionStorage.getItem('SignupSID', 'string'), [userDataTrigger]);
  // const registrationData = useMemo(() => getFromSession('registration'), [userDataTrigger]);
  const [keyValidation, setKeyValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPackage, setIsLoadingPackage] = useState(false);
  const [passValidation, setPassValidation] = useState();
  const [validatingKey, setValidatingKey] = useState(false);
  const registration = useMemo(() => getFromSession('registration'), []);
  const { authPost } = useApi();
  const [step, setStep] = useState(1);
  const [paymentMethod, setpaymentMethod] = useState('crypto');

  const onSubmitKey = async () => {
    if (!key) {
      setModalData({
        title: 'Invalid pass token',
        buttonText: t('Ok'),
        type: 'error',
        onModalClose: () => setModalData(null),
      });
    }
    if (!selectedPackage) {
      setPassValidation(t('Please choose a pass type!'));
    }
    if (key && selectedPackage) {
      setValidatingKey(true);
      try {
        const res = await payWithCode(signupUser, key);
        if (res.success) {
          setModalData({
            title: 'Key used successfully',
            buttonText: t('Ok'),
            type: 'success',
            onModalClose: () => {
              // save user send to success
              setModalData(null);
              sessionStorage.setItem('paidSignupUser', sessionStorage.getItem('signupUser'));
              navigate('/thank-you');
            },
          });
        } else {
          setModalData({
            title: res.message,
            buttonText: t('Ok'),
            type: 'error',
            onModalClose: () => setModalData(null),
          });
        }
      } catch (err) {
        setModalData({
          title: err,
          buttonText: t('Ok'),
          type: 'error',
          onModalClose: () => setModalData(null),
        });
      }
    }
    setValidatingKey(false);
  };

  const fetchPackages = async () => {
    const _fetch = async () => {
      try {
        setLoading(true);
        const res = await getPackages();
        setPackages(res.sort((a, b) => b.price - a.price));
        setSelectedPackage(res?.sort((a, b) => b.price - a.price)[0].id);

        setLoading(false);
      } catch (err) {
        console.log(err);
      }
    };
    try {
      _fetch();
    } catch (err) {
      toast.error(typeof err === 'string' ? err : t(err?.message));
    }
  };

  const clearSession = () => {
    // Clear signup session
    sessionStorage.removeItem('signupParent');
    sessionStorage.removeItem('registration');
    sessionStorage.removeItem('paidSignupUser');
    sessionStorage.removeItem('SignupSID');
    sessionStorage.removeItem('signupUser');
    localStorage.removeItem('_puid');
    localStorage.removeItem('token');
  };

  const onSubmitBitcoin = async () => {
    if (!selectedPackage) {
      setPassValidation(t('Please choose a pass type!'));
      return;
    }
    setIsLoading(true);
    try {
      const _sid = sessionStorage.getItem('SignupSID');

      const result = await nonAuthPost('/payments/passes/start', {
        data: { SID: _sid, passType: selectedPackage, amountOfPasses: 1 },
        headers: {
          Authorization: `Bearer ${sessionStorage
            .getItem('token')
            .substring(1, sessionStorage.getItem('token').length - 1)}`,
        },
      });

      setPurchaseData({ ...result, SID: _sid, fromSignup: true });
      sessionStorage.setItem('_puid', result.PUID);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const checkVerificationToken = async () => {
    if (!signupUser) {
      navigate('/');
    }
    if (signupUser?.source === 'google' || signupUser?.source === 'apple' || signupUser?.emailVerified) {
      fetchPackages();
    } else {
      try {
        let resUser;

        if (process.env.NODE_ENV !== 'development') {
          const _token = window.location.search.split('verificationToken=');
          resUser = await nonAuthPost('/auth/verify-link', { data: { verificationToken: _token[1] } });
        } else {
          resUser = { user: signupUser };
        }
        const eUser = resUser.user;
        const enrolledParentID = await nonAuthGet(`dashboard/enroll/${eUser.EnrolledParentID}`);
        sessionStorage.setItem('SignupSID', eUser.SID);
        sessionStorage.setItem('signupUser', JSON.stringify(eUser));
        if (resUser.token) {
          sessionStorage.setItem('token', resUser.token);
        }
        sessionStorage.setItem('signupParent', JSON.stringify(enrolledParentID));
        sessionStorage.setItem(
          'registration',
          JSON.stringify({
            givenName: eUser.Name,
            familyName: eUser.Surname,
            email: eUser.Email,
            source: 'google',
          }),
        );

        // skip verification if in development
        if (process.env.NODE_ENV !== 'development') {
          if (!eUser?.emailVerified) {
            navigate('/verify-email');
          } else if (eUser.emailVerified) {
            fetchPackages();
            setUserDataTrigger(1);
            console.log('stay here');
          } else {
            console.log('navigate');
            navigate('/');
          }
        } else {
          fetchPackages();
          navigate('/dashboard/enroll-now/pay');
        }
      } catch (err) {
        console.log(err);
        if (process.env.NODE_ENV !== 'development') {
          if (err === 'Not found') {
            navigate('/token-expired');
          } else if (err === 'Token expired') {
            navigate('/token-expired');
          } else {
            navigate('/');
          }
        }
      }
    }
  };

  useEffect(() => {
    checkVerificationToken();
  }, []);

  const submitPayment = async (canceled = true) => {
    if (canceled) {
      handleModalClose(canceled);
    } else {
      const { source } = registration;
      setLoading(true);

      try {
        if (source === 'apple') {
          const res = await logInApple(registration);
          clearSession();
        } else {
          const res = await logInGoogle({ user: registration });
          clearSession();
        }
      } catch (err) {
        console.log(err);
      }

      setIsLoading(false);
    }
  };

  const affiliateOnlyClick = async () => {
    try {
      const user = { ...signupUser, isProduct: false };
      if (user) {
        const res = await authPost('/auth/signup/affiliate-only', { data: user });
        sessionStorage.setItem('signupUser', JSON.stringify(res));
        sessionStorage.setItem('paidSignupUser', JSON.stringify(res));
        let token = sessionStorage.getItem('token');
        if (token) {
          token = token.replace(/['"()]/g, '');
        }
        navigate('/thank-you', { state: { user: res, token } });
      } else {
        navigate('/');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onContinue = () => {
    if (paymentMethod === 'creditCard') {
      setStep(2);
    } else {
      onSubmitBitcoin();
    }
  };

  return (
    <Layout style={{ padding: '46px 56px' }}>
      <div className="sign-up-page">
        <div className="signup-form">
          {
            step === 1 ? (
              <div className="admin-login">
                <h1 className="mb-8">{t('Select Pass Type')}</h1>
                {loading && isLoading ? (
                  <div className="flex flex-row justify-center mb-8">
                    <CircularProgress />
                  </div>
                ) : (
                  <div className="packages mb-8" style={{ position: 'relative' }}>
                    {(isLoadingPackage || validatingKey) && (
                      <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
                        <CircularProgress />
                      </div>
                    )}
                    {passValidation && (
                      <Typography
                        sx={{
                          color: 'brown !important',
                          fontSize: '14px',
                          opacity: '100% !important',
                        }}
                      >
                        {passValidation}
                      </Typography>
                    )}
                    {packages.map((p) => (
                      <div
                        key={p.id}
                        className={clsx('package', selectedPackage === p.id && 'selected')}
                        onClick={() => {
                          setSelectedPackage(p.id);
                        }}
                      >
                        <h3>{p.name}</h3>
                        <p>{`$${p.price} / ${p.days} days`}</p>
                      </div>
                    ))}
                  </div>
                )}

                {/* <Container sx={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'center', mb: 4,
                }}
                >
                  <RadioGroup
                    sx={{ flexDirection: 'row', justifyContent: 'center', color: 'white' }}
                    onChange={(e) => { setpaymentMethod(e); }}
                    value={paymentMethod}
                    setErrors={() => { }}
                    name="paymentMethod"
                    options={[{ label: 'Credit Card', value: 'creditCard' }, { value: 'crypto', label: 'Crypto' }]}
                  />
                </Container> */}

                <Button
                  type="button"
                  className="full-width"
                  onClick={onContinue}
                  disabled={validatingKey}
                >
                  {t('Continue to Payment')}
                </Button>

                <Divider sx={{ mt: 5, mb: 3 }} />

                <h2 className="mb-3 mt-4">{t('Have a key?')}</h2>

                <EnterKey
                  passKey={key}
                  setKey={setKey}
                  validatingKey={validatingKey}
                  onSubmitKey={onSubmitKey}
                  keyValidation={keyValidation}
                />

                {passValidation && (
                  <Typography
                    sx={{
                      color: 'brown !important',
                      fontSize: '14px',
                      opacity: '100% !important',
                      mb: 3,
                      mt: 2,
                    }}
                  >
                    {passValidation}
                  </Typography>
                )}

                {
                  signupUser?.isAffiliate && <AffiliateLink affiliateOnlyClick={affiliateOnlyClick} />
                }

              </div>
            )
              : (
                <StripePay
                  selectedPackage={selectedPackage}
                  setStep={setStep}
                  signupUser={signupUser}
                />
              )
          }

        </div>
      </div>

      {renderModals({
        type: modalData?.type,
        open: !!modalData,
        data: { title: modalData?.title, buttonText: modalData?.buttonText },
        onModalClose: modalData?.onModalClose,
      })}

      {purchaseData && <PurchasePassModal data={purchaseData} handleModalClose={submitPayment} />}
    </Layout>
  );
}
