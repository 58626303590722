import { Navigate } from 'react-router-dom';
import { useAuthentication } from '../utils/store';
import AppLoader from '../components/AppLoader';

const ProtectedRoute = ({ children }) => {
  const { token, authenticationLoading } = useAuthentication();

  if (authenticationLoading) {
    return <AppLoader />;
  }
  if (!token) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
