/* eslint-disable no-param-reassign */
import { useState, useCallback, useEffect } from 'react';
import useApi from '../../api';
import { passTypesColors } from '../../../features/Admin/PassTypes/config';
import { getUserObject } from '../../storage';

/**
 * Hook where AppContext functionallity is created
 */
export default function useAppStore() {
  const { authGet } = useApi();
  const [passTypes, setPassTypes] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loadingPassTypes, setLoadingPassTypes] = useState(false);
  const [loadingPackages, setLoadingPackages] = useState(false);

  const getPassTypes = useCallback(async () => {
    try {
      setLoadingPassTypes(true);
      let uri = '/pass-types';
      const user = await getUserObject();
      if (user) {
        uri = `${uri}?user=${user.SID}`;
      }
      const res = await authGet(uri);
      setPassTypes(res.map((i, index) => ({ ...i, color: passTypesColors[index] })));
    } catch (err) {
      console.log(err);
    }
    setLoadingPassTypes(false);
  }, [authGet, setLoadingPassTypes, setPassTypes]);

  const updatePassTypes = useCallback(
    (passType) => {
      setLoadingPassTypes(true);
      passTypes.map((type) => (type.id === passType.id ? { ...type, ...passType } : type));
      setPassTypes(passTypes);
      setLoadingPassTypes(false);
    },
    [setLoadingPassTypes, setPassTypes],
  );

  const createPassTypes = useCallback(
    (passType) => {
      setLoadingPassTypes(true);
      passTypes.push(passType);
      setPassTypes(passTypes);
      setLoadingPassTypes(false);
    },
    [setLoadingPassTypes, setPassTypes],
  );

  const updatePackage = useCallback(
    (pack) => {
      setLoadingPackages(true);
      packages.map((p) => (p.id === pack.id ? { ...p, ...pack } : p));
      setPackages(packages);
      setLoadingPackages(false);
    },
    [setLoadingPackages, setPackages],
  );

  const createPackage = useCallback(
    (pack) => {
      setLoadingPackages(true);
      packages.push(pack);
      setPackages(packages);
      setLoadingPackages(false);
    },
    [setLoadingPackages, setPackages],
  );

  const getPackages = useCallback(async () => {
    try {
      setLoadingPackages(true);
      const res = await authGet('/packages');
      setPackages(res);
    } catch (err) {
      console.log(err);
    }
    setLoadingPackages(false);
  }, []);

  // useEffect(() => {
  //   if (passTypes.length === 0) {
  //     getPassTypes();
  //   }
  //   if (packages.length === 0) {
  //     getPackages();
  //   }
  // }, []);

  return {
    getPassTypes,
    updatePassTypes,
    createPassTypes,
    passTypes,
    loadingPassTypes,
    getPackages,
    updatePackage,
    createPackage,
    packages,
    loadingPackages,
  };
}
