import { Container, Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from './components/header';
import WhatIs from './components/whatIs';
import Memberships from './components/membership';
import OurPhilosophy from './components/ourPhilosophy';
import Verticals from './components/verticals';
import ScaleYourBusiness from './components/scaleYourBusiness';
import Level from './components/level';
import Team from './components/team';
import Results from './components/results';
import Faq from './components/faq';
import { getFromSession } from '../../utils/session';

export default function Lander() {
  //
  //  return (
  //    <iframe src="/assets/lander/index.html" title="My Page" width="100%" height="100%" />
  //  );

  const signupParent = useMemo(() => getFromSession('signupParent'), []);
  const navigate = useNavigate();

  const onSignup = () => {
    if (signupParent) {
      navigate('/dashboard/signin');
    } else {
      navigate('/dashboard/registration/new');
    }
  };

  useEffect(() => {
    if (signupParent) {
      navigate('/dashboard/signin');
    } else {
      navigate('/dashboard/registration/new');
    }
  }, []);

  return (
    <Grid container className="lander-page">

      {/* // Header and video */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Header onSignup={onSignup} />
      </Grid>

      {/* // What is trec */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <WhatIs onSignup={onSignup} />
      </Grid>

      {/* //  what do i get with my membership */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Memberships />
      </Grid>

      {/* // out philosophy */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <OurPhilosophy onSignup={onSignup} />
      </Grid>

      {/* // become an expert */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Verticals />
      </Grid>

      {/* // scale your business */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <ScaleYourBusiness onSignup={onSignup} />
      </Grid>

      {/* // how does it work */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Level />
      </Grid>

      {/* //  a unique team of sector experts */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Team onSignup={onSignup} />
      </Grid>

      {/* //  get results fast */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Results />
      </Grid>

      {/* // FAQ */}
      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>

        <Faq onSignup={onSignup} />
      </Grid>

    </Grid>

  );
}
