/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';
import AppleSignin from 'react-apple-signin-auth';
import { Typography } from '@mui/material';
import { useMemo } from 'react';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import useAuthenticationApi from '../api';
import Button from '../../../components/Button';
import Layout from '../../../components/AuthLayout';
// import { useAuthentication } from '../../../utils/store';
import { useNonAuthApi } from '../../../utils/api';
import { getFromSession } from '../../../utils/session';

export default function Login() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const { authenticateUser } = useAuthentication();
  const { getGoogleUser, logInGoogle, logInApple } = useAuthenticationApi();
  const { nonAuthGet } = useNonAuthApi();
  const signupParent = useMemo(() => getFromSession('signupParent'), []);

  const onSignInGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const user = await getGoogleUser(tokenResponse.access_token);
      const _user = {
        givenName: user.given_name,
        familyName: user.family_name,
        picture: user.picture,
        email: user.email,
        emailVerified: user.email_verified,
        locale: user.locale,
        sub: user.sub,
        name: user.name,
      };

      try {
        const res = await logInGoogle({ user: _user });
        const enrolledParentID = await nonAuthGet(`dashboard/enroll/${res.user.EnrolledParentID}`);

        // check if the payment process is done
        if (res.user.signupStep === 'finished') {
          navigate('/user-exists');
        } else {
          sessionStorage.setItem('SignupSID', res.user.SID);
          sessionStorage.setItem('signupUser', JSON.stringify(res.user));
          sessionStorage.setItem('token', JSON.stringify(res.token));
          sessionStorage.setItem('signupParent', JSON.stringify(enrolledParentID));
          sessionStorage.setItem(
            'registration',
            JSON.stringify({
              givenName: res.user.Name,
              familyName: res.user.Surname,
              email: res.user.Email,
              source: 'google',
            }),
          );
          navigate('/dashboard/enroll-now/pay');
        }
      } catch (err) {
        if (err === 'Unauthorized') {
          navigate('/dashboard/registration/new');
          sessionStorage.setItem('registration', JSON.stringify({ ..._user, source: 'google' }));
        }
      }
    },
  });

  const onSignInApple = async (data) => {
    try {
      const res = await logInApple(data);
      const enrolledParentID = await nonAuthGet(`dashboard/enroll/${res.user.EnrolledParentID}`);

      if (res?.user?.signupStep === 'finished') {
        navigate('/user-exists');
      } else {
        sessionStorage.setItem('SignupSID', res.user.SID);
        sessionStorage.setItem('signupUser', JSON.stringify(res.user));
        sessionStorage.setItem('token', JSON.stringify(res.token));
        sessionStorage.setItem('signupParent', JSON.stringify(enrolledParentID));

        console.log('--onSignInApple', res);

        sessionStorage.setItem(
          'registration',
          JSON.stringify({
            givenName: res?.user?.name?.firstName || '',
            familyName: res?.user?.name?.lastName || '',
            email: res?.user?.email || '',
            source: 'apple',
            user: {
              email: res.user.email,
              name: {
                firstName: res.user.firstName,
                lastName: res.user.lastName,
              },
            },
            ...data,
          }),
        );
        window.location.href = 'dashboard/enroll-now/pay';
        // navigate('/dashboard/enroll-now/pay');
      }
    } catch (err) {
      console.log('apple-sign-error', err);
      if (err === 'Unauthorized') {
        navigate('/dashboard/registration/new');
        sessionStorage.setItem('registration', JSON.stringify({ ...data, source: 'apple' }));
      }
    }
  };

  const onSignInEmail = () => {
    navigate('/dashboard/signin/form');
  };

  return (
    <Layout>
      <div className="login-page">
        <div className="flex-row justify-center mb-7">
          <img style={{ width: '70px' }} alt="logo-black" src="/assets/layout/tft_logo_black.svg" />
        </div>

        <h1 className="mb-13">{t('Sign Up')}</h1>

        {signupParent && (
          <Typography
            sx={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
              mb: 3,
              color: 'white !important',
            }}
          >
            {' Your Sponsor is :  '}
            <span className="sponsor">{`${signupParent?.firstName} ${signupParent?.lastName}`}</span>
          </Typography>
        )}

        <Button
          onClick={onSignInGoogle}
          icon={<img src="/assets/auth/google.svg" alt="google" />}
          bordered
          className="mb-3"
        >
          Google
        </Button>

        {/* <AppleSignin
          authOptions={{
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: 'email name',
            redirectURI: process.env.REACT_APP_APPLE_CALLBACK_URL,
            usePopup: true,
          }}
          uiType="dark"
          className="apple-auth-btn"
          noDefaultStyle={false}
          onSuccess={async (response) => {
            onSignInApple(response);
          }}
          onError={(error) => console.error(error)}
          skipScript={false}
          iconProp={{ style: { marginTop: '10px' } }}
          render={(props) => (
            <Button {...props} icon={<img src="/assets/auth/apple.svg" alt="apple" />} bordered>
              Apple
            </Button>
          )}
        /> */}

        <Button
          onClick={onSignInEmail}
          icon={<AlternateEmailIcon fontSize="large" />}
          bordered
          className="mb-3 mt-3"
        >
          Email
        </Button>
      </div>
    </Layout>
  );
}
