import {
  Elements,
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useMemo, useState } from 'react';
import CheckoutForm from './checkoutForm';
import useApi from '../../../../../utils/api';
import Button from '../../../../../components/Button';

const STRIPEKEY = 'pk_test_51P5vNLGqZIYjOkVtMvnWCFno4toFpRhDY0p37JXXacq9GZjrn0H02lRx94gGdvpzYpWLmMSL7Aifo7gRevBHDdZj00NNuAuAm9';
const id = 'prod_PvnQ0jGJPVQ21o';

const stripePromise = loadStripe(STRIPEKEY);

export default function StripePay({ selectedPackage, setStep, signupUser }) {
  const [clientSecret, setClientSecret] = useState('');
  const { authGet, authPost } = useApi();

  const getClientSecret = async () => {
    try {
      const res = await authPost('/stripe/create-checkout-session', { data: { passTypeId: selectedPackage, user: signupUser } });
      console.log(res);
      setClientSecret(res.clientSecret);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getClientSecret();
  }, []);

  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#cc9c5e',
      colorBackground: '#191a1d',
      colorText: '#ffffff',
      colorDanger: '#df1b41',
      fontFamily: 'Ideal Sans, system-ui, sans-serif',
      spacingUnit: '5px',
      borderRadius: '6px',
    },
  };
  const options = useMemo(() => ({
    clientSecret,
  }), [clientSecret]); 

  const cancel = () => {
    setStep(1);
  };
  
  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
      <Button bordered onClick={(e) => cancel()} style={{ marginTop: '15px' }} id="cancel">
        <span id="button-text">
          Cancel
        </span>
      </Button>
    </div>
   
  );
}
