/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { faqItems } from '../config';
import Button from '../../../components/Button';

export default function Faq({ onSignup }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (

    <div className="faq">

      <div className="container">

        <div className="layout-grid">

          <div className="list">

            {
              faqItems.map((i, index) => (
                <QA key={index} t={t} i={i} />
              ))
            }

          </div>

          <div className="content">

            <h2>
              FAQ
            </h2>

            <p>
              {t(
                'Lorem ipsum dolor sit amet, consequatctetur adipisicing elit, sed do eiusmod tempor incidaidunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
              )}
            </p>

          </div>

        </div>

      </div>

      <div className="footer">
        <img className="gold" src="assets/gold.webp" alt="gold" />

        <div className="container">

          <img className="footer-img" src="assets/footer.svg" alt="footer" />
          <p>

            ©
            {' '}
            {new Date().getFullYear()}
            {' '}
            {t('The Founders Team. All rights reserved.')}

          </p>

          <Button onClick={() => { onSignup(); }} style={{ width: '150px', height: 'fit-content' }}>
            {' '}
            {t('Join Now')}
          </Button>

        </div>

      </div>

    </div>
  );
}

const QA = ({ t, i }) => {
  // State to track which answer is open
  const [openAnswerIndex, setOpenAnswerIndex] = useState(false);

  // Function to toggle the answer
  const toggleAnswer = () => {
    setOpenAnswerIndex(!openAnswerIndex);
  };

  return (
    <div className="item">
      <div onClick={toggleAnswer} className="question">
        <div>
          {t(i.question)}
        </div>
        <ExpandMoreIcon className={`arrow ${openAnswerIndex ? 'open' : ''}`} />
      </div>
      <div className={`answer ${openAnswerIndex ? 'open' : ''}`}>
        <div className="block">
          <p>
            {t(i.answer)}
          </p>
        </div>
      </div>
    </div>
  );
};
