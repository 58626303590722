/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-unescaped-entities */
import { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export default function Level() {
  const { t } = useTranslation();
  return (
    <div className="level">

      <div className="container">

        <div className="level-title">

          <h2>{t('How does it work!')}</h2>

        </div>

        <div className="features-tabs" />

        <YourComponent />

      </div>

    </div>
  );
}

const Tab = ({
  id, label, content, open, t,
}) => (
  <div data-w-tab={id} className={`pane w-tab-pane${open ? 'open' : ''}`} role="tabpanel" id={`w-tabs-0-data-w-pane-${id}`} aria-labelledby={`w-tabs-0-data-w-tab-${id}`}>
    <div className="pane__lo">
      <div className="pane__info">
        {content}
      </div>
      <PaneList t={t} />
    </div>
  </div>
);

const Tabs = ({ tabs }) => {
  const { t } = useTranslation();

  const selectRef = useRef();
  const [currentTab, setCurrentTab] = useState(tabs[0].id);

  const handleTabClick = (id) => {
    setCurrentTab(id);
  };

  const [openSelect, setopenSelect] = useState(false);

  const onSelectClick = () => {
    setopenSelect(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setopenSelect(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div data-current={currentTab} data-easing="ease" data-duration-in="300" data-duration-out="100" className="tabs is--level w-tabs features__tabs-dd is--level">
      <div className="tabs__nav is--level w-tab-menu" role="tablist">

        <Select selectRef={selectRef} value={tabs[currentTab].subtitle} onClick={onSelectClick} />
       
        <div className={`${openSelect ? 'open-select' : 'close-select'}`}>
      
          {tabs.map((tab) => (
            <div
              href={`${tab.id}`}
              className={`besa-tabs tab w-inline-block w-tab-link ${currentTab === tab.id ? 'w--current' : ''} `}
              data-w-tab={tab.id}
              onClick={() => handleTabClick(tab.id)}
              role="tab"
              aria-controls={`w-tabs-0-data-w-pane-${tab.id}`}
              aria-selected={currentTab === tab.id}
            >
              <div className="tab__s t-gold">{t(tab.label)}</div>
              <div className="f-b">{t(tab.subtitle)}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="tabs-content w-tab-content">
        {tabs.map((tab) => (
          <Tab
            t={t}
            open={currentTab === tab.id}
            key={tab.id}
            id={tab.id} 
            label={tab.label}
            content={tab.content}
          />
        ))}
      </div>
    </div>
  );
};

export function YourComponent() {
  const { t } = useTranslation();

  const tabs = [
    {
      id: '0',
      index: 0,
      label: 'Level I',
      subtitle: 'Basic',
      content: (
        <>
          <p className="pane__p-l">
            {t(`            Premium membership offers all the great benefits of our basic tier, plus exclusive access to live events, direct Q&A with our masterminds, and special monthly sessions only available to premium members.
`)}
          </p>
          <p className="pane__p">
            {t(`            This invaluable experience allows you to stay abreast of the latest trends in the marketplace and gain insights into the art of evaluating project deals. As a dedicated learner, you'll find each month's rollout archived in your network access portal, fostering a dynamic and active community environment for your continuous growth. Elevate your knowledge and seize this opportunity for just $1,500 per month.
`)}
          </p>
          <h3 className="level__price">
            {t('$150/month')}
          </h3>
        </>
      ),
      list: [
        'Everything in our Basic package plus...', 
        'Access to live speaking events and experiences across the world, featuring some of the biggest masterminds in the business.',
        'Attend private online events not available in our basic package.',
        'Private Access to ask questions and discuss opportunities with our TREC masterminds and graduates.',
      ],
    },
    {
      id: '1',
      index: 1,
      label: 'Level II',
      subtitle: 'Premium',
      content: (
        <>
          <p className="pane__p-l">
            {t(`            Premium membership offers all the great benefits of our basic tier, plus exclusive access to live events, direct Q&A with our masterminds, and special monthly sessions only available to premium members.
`)}
          </p>
          <p className="pane__p">
            {t(`            This invaluable experience allows you to stay abreast of the latest trends in the marketplace and gain insights into the art of evaluating project deals. As a dedicated learner, you'll find each month's rollout archived in your network access portal, fostering a dynamic and active community environment for your continuous growth. Elevate your knowledge and seize this opportunity for just $1,500 per month.
`)}
          </p>
          <h3 className="level__price">
            {t('$150/month')}
          </h3>
        </>
      ),
      list: [
        'Everything in our Basic package plus...', 
        'Access to live speaking events and experiences across the world, featuring some of the biggest masterminds in the business.',
        'Attend private online events not available in our basic package.',
        'Private Access to ask questions and discuss opportunities with our TREC masterminds and graduates.',
      ],
    },
  ];

  return <Tabs tabs={tabs} />;
}

const PaneList = ({ t }) => (
  <div id="w-node-_0ebcfc87-86d0-df0d-bafc-f48f4aedebe2-d05b13c2" className="pane__list-w">
    <div id="w-node-_0ebcfc87-86d0-df0d-bafc-f48f4aedebe3-d05b13c2" className="pane__list is--level">
      <div className="pane__item-lo">
        <div className="pane__item__left">
          <div className="pane__step-w">
            <div className="pane__step is--level">1</div>
            <img
              loading="lazy"
              src="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65cd524d020370565343787e_special_offer_exclusive-4.svg"
              alt=""
              className="pane__step__ico"
            />
          </div>
         
        </div>
        {t(' Everything in our Basic package plus...')}
      </div>
      <div className="pane__item-lo">
        <div className="pane__item__left">
          <div className="pane__step-w">
            <div className="pane__step is--level">2</div>
            <img
              loading="lazy"
              src="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65cd524d020370565343787e_special_offer_exclusive-4.svg"
              alt=""
              className="pane__step__ico"
            />
          </div>
        
        </div>
        {t(` Access to live speaking events and experiences across the world, 
        featuring some of the biggest masterminds in the business.`)}
      </div>
      <div className="pane__item-lo">
        <div className="pane__item__left">
          <div className="pane__step-w">
            <div className="pane__step is--level">3</div>
            <img
              loading="lazy"
              src="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65cd524d020370565343787e_special_offer_exclusive-4.svg"
              alt=""
              className="pane__step__ico"
            />
          </div>
         
        </div>
        {t('Attend private online events not available in our basic package.')}

      </div>
      <div className="pane__item-lo">
        <div className="pane__item__left">
          <div className="pane__step-w">
            <div className="pane__step is--level">4</div>
            <img
              loading="lazy"
              src="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65cd524dc5e1610bf8e5b066_special_offer_exclusive-3.svg"
              alt=""
              className="pane__step__ico"
            />
          </div>
        
        </div>
        {t(`Private Access to ask questions and discuss opportunities with 
        our TREC masterminds and graduates.`)}
      </div>
    </div>
    <div className="level__shadow">
      <img
        sizes="(max-width: 479px) 100vw, (max-width: 767px) 329.3021240234375px, (max-width: 991px) 43vw, 38vw"
        height="Auto"
        alt=""
        src="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e9bdb296b0b758502ad3be_Ellipse%2020_q85.webp"
        loading="lazy"
        srcSet="https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e9bdb296b0b758502ad3be_Ellipse%2020_q85-p-500.webp 500w, https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e9bdb296b0b758502ad3be_Ellipse%2020_q85-p-800.webp 800w, https://assets-global.website-files.com/65a5c74d475be4e728d1e70d/65e9bdb296b0b758502ad3be_Ellipse%2020_q85.webp 941w"
      />
    </div>
  </div>
);

const Select = ({ value, onClick, selectRef }) => (
  <div ref={selectRef} className=" besa-select select-container">
    <div className="select" value={value} onClick={onClick}>
      <p style={{ fontSize: '18px' }}>
        {value}
      </p>
    </div>
    <div className="select-arrow">
      <div style={{
        border: 'solid black',
        borderWidth: '0 2px 2px 0', 
        display: 'inline-block',
        padding: '3px',
        height: '5px',
        width: '4px',
      }}
      />

    </div>
  </div>
);
