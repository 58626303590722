import { useTranslation } from 'react-i18next';

export default function Verticals() {
  const { t } = useTranslation();
  return (
    <div className="verticals">

      <div className="background">

        <img src="assets/smiling-money.webp" alt="smiling" />

      </div>
      <div className="content">

        <div className="info">

          <h2>
            {t('Become an Expert in all three verticals')}
          </h2>

          <div className="list-cont">

            <div className="list">

              <div className="item">

                <img src="assets/buildings.svg" alt="buildings" />
                <h3>{t('Real Estate')}</h3>
                <p>{t("By employing real estate investments to preserve wealth, and combining them with cryptocurrency innovations, we've navigated past traditional constraints. Our goal is to provide our members with insights and experiences from leading real estate investors, demonstrating how real estate can be used to elevate net worth.")}</p>

              </div>

              <div className="item">

                <img src="assets/phone.svg" alt="phone" />
                <h3>{t('Technology')}</h3>
                <p>{t("With a successful track record of investing millions in tech initiatives, we're excited to share our expertise with you through online courses and live seminars. Additionally, we will introduce tech entrepreneurs seeking partners or assistance to amplify their existing success.")}</p>

              </div>

              <div className="item">

                <img src="assets/bitcoin.svg" alt="bitcoin" />
                <h3>{t('Crypto')}</h3>
                <p> 
                  {' '}
                  {t('Learn the ins and outs of navigating the Crypto marketplace using both long-term, medium-term, and short-term investments. Identify which style best suits your personality, and how our Masterminds have been able to use those strategies to achieve success.')}
                  {' '}
                </p>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  );
}
