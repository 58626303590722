import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import membershipContentList from '../config';

export default function Memberships() {
  const { t } = useTranslation();
  return (
    <div className="memberships">

      <div className="title middle">
        {t('What do I get with my membership?')}
      </div>

      <div className="container">

        <div className="list">

          {
                membershipContentList.map((i, iindex) => (
                  <div key={`item${iindex}`} className="item">
                    <div className="sections">
                      <h3>{t(i.title)}</h3>
                      <img src={i.image} alt={i.title} />
                      <p>
                        {t(i.desc)}
                      </p>
                      <ul>
                        {i.descList.map((j, jindex) => (
                          <li key={`li${jindex}`}>
                            <p>{t(j)}</p>
                            
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                ))
            }

        </div>

      </div>
    </div>
  );
}
