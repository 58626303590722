const membershipContentList = [
  {
    id: 0,
    title: 'Mastermind Community',
    desc: `A cultivated global network dedicated to those who aim to elevate their lives financially and mentally.
         Our product is more than just a membership; it's a community with a shared commitment to personal development.
         Join us and unlock your full potential alongside peers who share your ambition for comprehensive growth!`,
    descList: [
      'Access to a diverse group of thought leaders and innovators',
      'A diverse library of resources for wealth creation',
      'Secrets of how the wealthy manage money',
      'Mental growth workshops designed to expand your mindset',
    ],
    image: 'assets/hands.webp',

  },
  {
    id: 1,
    title: 'Digital Products Library',
    desc: `This meticulously curated repository is designed to equip you with cutting-edge knowledge and insights, fostering expertise in investment strategies, market trends, and innovative technologies.
     Elevate your skill set and stay at the forefront of industry in Crypto, Real Estate, and Technology.`,
    descList: [
      'Expansive collection of lessons from seasoned professionals',
      'Expert-led tutorials, case studies, and interactive webinars',
      'New lessons, tutorials and webinars added weekly',
    ],
    image: 'assets/smiling-money.webp',

  },
  {
    id: 2,
    title: 'Access to deal network',
    desc: `Our platform provides access to a constant flow of high-quality deals, alongside a network of like minded individuals open to collaboration. Immerse yourself in a community where deal flow meets potential, allowing you to discover unique investment opportunities,
     strategic partnerships, and collaborative ventures across diverse industries. Step into a world of opportunity!`,
    descList: [
      'Unparalleled access to high quality deals in Crypto, Real Estate, and Technology',
      'Access to thriving network of successful businesses open to partnership and collaboration',

    ],
    image: 'assets/talking.webp',

  },
];

export const faqItems = [
  {
    id: 0,
    question: 'Will I get access to all the courses and events once I join?',
    answer: ' Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
  },

  {
    id: 1,
    question: 'Can I cancel at any time?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
  },

  {
    id: 2,
    question: 'Is this program just for men?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
  },

  {
    id: 3,
    question: 'Does my age matter?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
  },

  {
    id: 4,
    question: 'I know nothing about Crypto, Real Estate or Tech. Is that a problem?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
  },

  {
    id: 5,
    question: 'I don’t have much time available, is that OK?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
  },

  {
    id: 6,
    question: 'I live in X Country, is that a problem?',
    answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat. Aenean faucibus nibh et justo cursus id rutrum lorem imperdiet. Nunc ut sem vitae risus tristique posuere.',
  },

];

export default membershipContentList;
