import { Divider, Typography } from '@mui/material';

export default function AffiliateLink({ affiliateOnlyClick }) {
  return (
    <>
      <Divider sx={{ mt: 5, mb: 3 }} />
      <Typography
        onClick={affiliateOnlyClick}
        sx={{
          ':hover': { fontWeight: '500', textDecoration: 'underline' }, textAlign: 'center', color: 'var(--primary-color) !important', cursor: 'pointer', fontStyle: 'italic',
        }}
        color="primary"
      >
        I wish to continue as an affiliate only, and will not pay for membership, so I will
        not have access to the The Founders Team product, masterminds, or live events.
      </Typography>
    </>
  );
}
